<script setup>
import { useProjectsFiltersStore } from '~/stores/projects-filters.store';
import { useRoute } from 'vue-router';
import ProjectFilterElement from './filters/element.vue'

const props = defineProps({
  country_id: {
    type: Number,
    required: true
  }
});
const route = useRoute();

const country_id = props.country_id

const isOpen = ref(false);

const filtersStore = useProjectsFiltersStore();
await filtersStore.fetchFilters(country_id).catch((error) => null);
filtersStore.updateFilterInputs(route.params);
</script>

<template>
	<button @click="isOpen = true" class="flex items-center gap-1 text-sm">
		<i class="fa-solid fa-filter fa-xl"></i>
	</button>

	<w-slideover v-model="isOpen" :isBotton="true" :width="`full`">
		<div class=" bg-gray-100 overflow-y-auto relative pb-6">
			<div class="flex justify-between items-center p-5 text-gray-700 fixed w-full bg-gray-100  z-50">
				<div class="text-2xl">Filter</div>
				<div class="ms-auto" @click="isOpen = false"><i class="fa-solid fa-close fa-xl"></i></div>
			</div>
			<div class="w-full flex flex-col px-4 mt-20 mb-14">
				<template v-for="filter in filtersStore.filters" :key="`elm_${filter._id}`">
					{{ filter.filter }}
					<ProjectFilterElement class="flex-grow" :filter="filter" :filtersStore="filtersStore"
						:useSlug="true" />
				</template>
			</div>
			<div class="flex gap-3 absolute bottom-0 w-full p-3 bg-white">
				<button @click="isOpen = false" class="bg-indigo-200 text-indigo-900 rounded-lg p-3 capitalize">
					Close
				</button>
				<NuxtLink @click="isOpen = false" :to="localePath(`/${filtersStore.filter_url}`)"
					class="w-full py-3 bg-indigo-900 rounded-lg justify-center items-center inline-flex  text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
					{{ $t('client.search') }}
				</NuxtLink>
			</div>
		</div>
	</w-slideover>
</template>
